<template>
  <ClientOnly>
    <Transition name="ucrs-fade">
      <div v-if="show" class="ucrs-message" :class="`ucrs-message--${variant}`">
        <SvgIcon
          :name="icon"
          :class="`ucrs-message__icon ucrs-message__icon--${size}`"
          aria-hidden="true"
        />
        <slot></slot>
      </div>
    </Transition>
  </ClientOnly>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      default: 'success',
    },

    size: {
      type: String,
      default: 'md',
    },

    icon: {
      type: String,
      default: 'check',
    },
  },
}
</script>

<style lang="postcss">
.ucrs-message {
  @apply rounded-lg font-medium text-2xl flex flex-col justify-center items-center text-center py-12 px-6;

  &__icon {
    @apply mb-6;
    &--md {
      @apply w-24 h-24;
    }

    &--lg {
      @apply w-64 h-64;
    }
  }

  &--success {
    @apply bg-green/5 text-green;
  }
}
</style>
