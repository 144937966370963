<template>
  <div>
    <div class="container">
      <StripBanner btn-text="Scopri come" link="/valuta-casa">
        Vuoi valutare la tua casa in modo semplice, veloce, affidabile?
      </StripBanner>
    </div>
    <section class="pt-16 pb-20 md:pt-20 md:pb-20">
      <div class="container">
        <SellMetalinks />
      </div>
    </section>
  </div>
</template>
<script>
// Components
import SellMetalinks from '~/components/SellMetalinks.vue'
import StripBanner from '~/components/StripBanner.vue'

export default {
  components: {
    SellMetalinks,
    StripBanner,
  },
}
</script>
