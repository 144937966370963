<template>
  <div class="text-black-light">
    <MessageBox
      v-if="$store.state.auth.loggedIn"
      :show="showMessage"
      class="mb-12"
    >
      <p>Email verificata con successo</p>
    </MessageBox>
    <h2 class="text-lg md:text-xl mb-16">
      Come si svolgerà la vendita della tua casa?<br aria-hidden="true" />
      <strong> Compila il form o chiamaci. Ecco cosa succederà:</strong>
    </h2>
    <div
      ref="SellBody"
      class="gap-y-8 sm:gap-y-16 grid grid-cols-1 sm:grid-cols-2 gap-x-13"
    >
      <IconCard
        v-for="(item, idx) in steps"
        :key="`IconCard_${idx}`"
        :icon="item.icon"
        :title="item.title"
        :content="item.content"
      >
      </IconCard>
    </div>
  </div>
</template>
<script>
// Components
import IconCard from '~/components/IconCard'
import MessageBox from '~/components/MessageBox'

export default {
  components: { MessageBox, IconCard },

  props: {
    cityName: {
      type: String,
      default: null,
    },

    showMessage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    steps() {
      const { cityName } = this

      return [
        {
          title: "Valutazione dell'immobile",
          icon: 'rating',
          content: cityName ? (
            <p>
              Il nostro <strong>consulente immobiliare di {cityName}</strong>,
              grazie ad una <strong>profonda conoscenza della tua zona</strong>{' '}
              e a <strong>tecnologie evolute</strong> di analisi dei dati di
              mercato, ti contatterà per realizzare il{' '}
              <a
                class="text-blue"
                href="/assets/pdf/facsimile-reportvalorecasa.pdf"
                target="_blank"
              >
                <strong>Report Valore Casa</strong>
              </a>
              <sup
                role="link"
                aria-label="Nota a piè di pagina numero 1"
                tabindex="0"
                class="cursor-pointer outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:rounded focus-visible:ring-blue"
                id="foot-evaluation-ref"
              >
                1
              </sup>
              .
            </p>
          ) : (
            <p>
              Puoi effettuare una prima <strong>valutazione online</strong>
              <sup
                role="link"
                aria-label="Nota a piè di pagina numero 1"
                tabindex="0"
                class="cursor-pointer outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:rounded focus-visible:ring-blue"
                id="foot-evaluation-ref"
              >
                1
              </sup>
              , istantanea e gratuita della tua casa, grazie alla tecnologia
              Casavo compilando il form in pagina. In seguito il nostro{' '}
              <strong>Consulente</strong> ti contatterà per realizzare il{' '}
              <a
                class="text-blue"
                href="/assets/pdf/facsimile-reportvalorecasa.pdf"
                target="_blank"
              >
                <strong>Report Valore Casa</strong>
              </a>
              <sup
                role="link"
                aria-label="Nota a piè di pagina numero 2"
                tabindex="0"
                class="cursor-pointer outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:rounded focus-visible:ring-blue"
                id="foot-report-ref"
              >
                2
              </sup>{' '}
              che ti offrirà un'analisi dettagliata sulle caratteristiche del
              tuo immobile.
            </p>
          ),
        },
        {
          title: 'Vantaggi',
          icon: 'vendo-vantaggi',
          content: (
            <ul class="ucrs-list ucrs-list--outside">
              <li>
                Avrai{' '}
                <strong>GRATIS l'Attestato di Prestazione Energetica</strong>
                <sup
                  role="link"
                  aria-label={`Nota a piè di pagina numero ${
                    cityName ? '2' : '3'
                  }`}
                  tabindex="0"
                  class="cursor-pointer outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:rounded focus-visible:ring-blue"
                  id="foot-ape-ref"
                >
                  {cityName ? '2' : '3'}
                </sup>{' '}
                e saremo al tuo fianco fino alla conclusione della
                compravendita.
              </li>
              <li>
                <strong>Registrati all'area riservata venditori</strong>,
                riceverai aggiornamenti costanti sui{' '}
                <strong>contatti ricevuti</strong> e un resoconto dettagliato
                sulle <strong>attività promozionali attive</strong> sul tuo
                immobile.
              </li>
            </ul>
          ),
        },
        {
          title: 'Visibilità',
          icon: 'vendo-promozione',
          content: cityName ? (
            <div>
              <p>
                Ti proporremo il{' '}
                <strong>
                  modo migliore per portare il tuo immobile sul mercato di{' '}
                  {cityName}
                </strong>
                :
              </p>
              <ul class="ucrs-list ucrs-list--outside">
                <li>
                  <strong>visite virtuali</strong> senza sopralluoghi;
                </li>
                <li>
                  <strong>promozione della tua abitazione</strong> sui{' '}
                  <strong>principali portali immobiliari</strong> e su{' '}
                  <strong>YouTube</strong>;
                </li>
              </ul>
            </div>
          ) : (
            <p>
              Il contatto diretto con un professionista della tua zona ti
              permetterà non solo di ricevere una valutazione del valore di
              mercato, ma anche{' '}
              <strong>
                un'assistenza lungo tutto il processo di compravendita
              </strong>
              .
            </p>
          ),
        },
        {
          title: 'Cerchi Casa?',
          icon: 'vendo-cerco',
          content: (
            <div>
              <p class="mb-6">
                Cerchi anche una nuova casa? Siamo qui per te! Se acquisti un
                immobile UniCredit RE Services avrai una provvigione agevolata
                del 2,5%.
              </p>

              <p class="text-xs font-sans">
                La provvigione agevolata per l'acquisto potrà essere applicata,
                qualora l'incarico di mediazione all'acquisto sia sottoscritto
                entro e non oltre i 12 mesi successivi alla data del rogito di
                vendita dell'immobile, oggetto di incarico UniCredit RE Services
                S.p.A., rientrante nella Promozione Cambio Casa.
              </p>
            </div>
          ),
        },
      ]
    },
  },

  mounted() {
    const { SellBody } = this.$refs

    const supNumbersEl = [...SellBody.querySelectorAll('sup[role="link"]')]

    supNumbersEl.forEach((el) => {
      const footnoteId = el.id.substring(0, el.id.length - 4)
      el.addEventListener('click', () =>
        this.onFootnoteRefInteraction(footnoteId)
      )
      el.addEventListener('keyup', (e) =>
        this.onFootnoteRefInteraction(footnoteId, e)
      )
    })
  },
  methods: {
    onFootnoteRefInteraction(id, event = null) {
      if (event && event.key !== 'Enter') return
      const footnoteEl = document.getElementById(id)

      window.scroll({
        top: footnoteEl.offsetTop,
        behavior: 'smooth',
      })
      footnoteEl.focus()
    },
  },
}
</script>
