<template>
  <div>
    <SellVisual />
    <SellMain>
      <SellFormCasavo slot="side" />
      <SellBody slot="body" :show-message="doubleOptin" />
    </SellMain>
    <SellFooter />
  </div>
</template>

<script>
// Mixins
import SellMixin from '~/mixins/sell'

// Components
import SellFormCasavo from '~/components/SellFormCasavo.vue'
import SellBody from '~/components/SellBody.vue'
import SellFooter from '~/components/SellFooter.vue'
import SellMain from '~/components/SellMain.vue'
import SellVisual from '~/components/SellVisual.vue'

export default {
  components: {
    SellFormCasavo,
    SellBody,
    SellFooter,
    SellMain,
    SellVisual,
  },

  mixins: [SellMixin],

  async asyncData({ route, $axios }) {
    let meta
    await $axios
      .get('/_api/api/v1/meta', {
        params: {
          slug: route.path,
        },
      })
      .then((res) => {
        meta = res.data.data
      })
      .catch(() => {
        meta = {
          title:
            'Vuoi vendere casa? Affidati alla professionalità di UniCredit RE Services!',
          description:
            'Una rete di professionisti qualificati che ti accompagna in ogni momento della compravendita. Vendi il tuo immobile con UniCredit RE Services.',
        }
      })

    return { meta }
  },
}
</script>
