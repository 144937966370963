// Libs
import { mapState } from 'vuex'

export default {
  middleware: ['sell-geo'],

  computed: {
    ...mapState(['doubleOptin']),
  },

  head() {
    return {
      title: this.meta.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.meta.description,
        },
      ],
    }
  },
}
