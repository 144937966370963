<template>
  <TwoColumnsPageContainer side-top="contact">
    <slot slot="side" name="side" />
    <slot slot="body" name="body" />

    <template slot="footer">
      <div class="my-16">
        <SellDisclaimer :evaluation="!cityName" />
      </div>
    </template>
  </TwoColumnsPageContainer>
</template>

<script>
// Components
import SellDisclaimer from '~/components/SellDisclaimer.vue'
import TwoColumnsPageContainer from '~/components/TwoColumnsPageContainer.vue'

export default {
  components: {
    SellDisclaimer,
    TwoColumnsPageContainer,
  },

  props: {
    cityName: {
      type: String,
      default: null,
    },
  },
}
</script>
