<template>
  <div class="ucrs-metaLinks">
    <p class="ucrs-metaLinks__header" v-text="header"></p>
    <section class="ucrs-metaLinks__inner">
      <div class="ucrs-metaLinks__list">
        <div
          v-for="(item, index) in links"
          :key="index"
          class="ucrs-metaLinks__item"
          :class="{
            'ucrs-metaLinks__item--hidden': index + 1 > showOnClose && !isOpen,
          }"
        >
          <NuxtLink
            v-dompurify-html="item[label]"
            :to="item[url]"
            class="ucrs-metaLinks__link"
          >
          </NuxtLink>
        </div>
      </div>
      <div
        v-if="showOnClose < links.length"
        v-show="!isOpen"
        class="inline-block px-2.4"
      >
        <span>&#8230;</span>
      </div>
      <div
        v-if="showOnClose < links.length"
        class="ucrs-metaLinks__toggle"
        @click="isOpen = !isOpen"
      >
        <span
          class="inline-block mr-1.6"
          v-text="isOpen ? showLessLabel : showMoreLabel"
        ></span>
        <OIcon
          pack="fa"
          :icon="isOpen ? `angle-up` : `angle-down`"
          size="medium"
        ></OIcon>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: '',
    },

    links: {
      type: Array,
      default: () => {
        return []
      },
    },

    showOnClose: {
      type: String,
      default: '10',
    },

    showMoreLabel: {
      type: String,
      default: 'Mostra tutti',
    },

    showLessLabel: {
      type: String,
      default: 'chiudi',
    },

    label: {
      type: String,
      default: 'text',
    },

    url: {
      type: String,
      default: 'href',
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
}
</script>

<style lang="postcss" scoped>
.ucrs-metaLinks {
  @apply text-sm;

  &__list {
    @apply inline;
  }

  &__item {
    @apply inline;

    &--hidden {
      @apply hidden;
    }

    &:not(:first-child)::before {
      @apply inline-block px-2.4 text-gray-dark;

      content: '|';
    }
  }

  &__link {
    @apply font-bold text-blue underline first-letter:uppercase;

    &:hover {
      @apply no-underline;
    }

    &:focus-visible {
      @apply outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-blue;
    }
  }

  &__toggle {
    @apply cursor-pointer font-bold text-blue whitespace-nowrap flex flex-nowrap items-center;

    @screen md {
      @apply inline-flex;
    }
  }
}
</style>
