<template>
  <MetaLinks
    v-if="links.length"
    header="Vendere casa a:"
    url="href"
    label="slug"
    :links="links"
    show-on-close="15"
  />
</template>

<script>
// Components
import MetaLinks from '~/components/MetaLinks.vue'

// Static assets
import SellGeo from '~/assets/json/sell-geo.json'

export default {
  components: {
    MetaLinks,
  },

  computed: {
    links() {
      const currentCityHref = this.$route.path
      const links = SellGeo.cities
        .filter((it) => it.href !== currentCityHref)
        .map((it) => ({
          ...it,
          slug: `Vendere casa a ${
            it.slug.charAt(0).toUpperCase() + it.slug.slice(1)
          }`,
        }))

      return links
    },
  },
}
</script>
